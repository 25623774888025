import apiCall from '../../api';


const service = {
    findall() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
                apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/fianza/api/v1/carpeta/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    }
}
export default service;