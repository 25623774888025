export default {
    getModelCarta: (state) => {
        return state.carta
    },
    getCartaTpl: (state) => {
        if(state.operacion==="list"){
            return "CartaList"
        } else if(state.operacion==="new"){
            return "CartaNew"
        } else if(state.operacion==="edit"){
            return "CartaEdit"
        }
    }, 
    getUrl: (state) => {
        return state.url
    }
}