import tupaService from '@/service/GsTupa/tupa'

export default {
    'TUPA_LIST': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.findall(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TUPA_LIST_DEPE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.findallTupa(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CONFIG': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.config(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    
    'TUPA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TUPA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TUPA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'FILE_UPLOAD': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tupaService.upload(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}