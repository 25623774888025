export default {
    getModelFinalizado: (state) => {
        return state.finalizado
    },
    getFinalizadoTpl: (state) => {
        if(state.operacion==="list"){
            return "FinalizadoList"
        } else if(state.operacion==="new"){
            return "FinalizadoNew"
        } else if(state.operacion==="edit"){
            return "FinalizadoEdit"
        }
    }, 
}