import multimediaService from '@/service/GsDialogo/multimedia'

export default {
    'MULTIMEDIA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            multimediaService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'MULTIMEDIA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            multimediaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'MULTIMEDIA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            multimediaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'MULTIMEDIA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            multimediaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
    
}