import cartaAction from './actions/carta'
import cartaGetter from './getters/carta'
import cartaMutation from './mutations/carta'
import cartaState from './states/carta'

import carpetaAction from './actions/carpeta'
import carpetaGetter from './getters/carpeta'
import carpetaMutation from './mutations/carpeta'
import carpetaState from './states/carpeta'

import bancoAction from './actions/banco'
import bancoGetter from './getters/banco'
import bancoMutation from './mutations/banco'
import bancoState from './states/banco'

import tipoFianzaAction from './actions/tipo_fianza'
import tipoFianzaGetter from './getters/tipo_fianza'
import tipoFianzaMutation from './mutations/tipo_fianza'
import tipoFianzaState from './states/tipo_fianza'

import contratistaAction from './actions/contratista'
import contratistaGetter from './getters/contratista'
import contratistaMutation from './mutations/contratista'
import contratistaState from './states/contratista'

import obraAction from './actions/obra'
import obraGetter from './getters/obra'
import obraMutation from './mutations/obra'
import obraState from './states/obra'

const actions = Object.assign({}, cartaAction, carpetaAction, bancoAction, tipoFianzaAction, obraAction, contratistaAction)
const getters = Object.assign({}, cartaGetter, carpetaGetter, bancoGetter, tipoFianzaGetter, obraGetter, contratistaGetter)
const mutations = Object.assign({}, cartaMutation, carpetaMutation, bancoMutation, tipoFianzaMutation, obraMutation, contratistaMutation)
const state = Object.assign({}, cartaState, carpetaState, bancoState, tipoFianzaState,obraState, contratistaState)

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
}