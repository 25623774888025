 <template >
  <div class="p-grid p-fluid dashboard" v-for="(item, key) in items" :key="key">
    <div class="p-col-12 p-md-6 p-xl-3" style="cursor: pointer">
      <router-link :to="click(item)">
        <div class="highlight-box">
          <div
            class="initials"
            v-bind:style="{ color: style(), 'background-color': style() }"
          >
            <span><i style="font-size: 40px" :class="item.icon"></i></span>
          </div>

          <div class="highlight-details">
            <i class="pi pi-fw pi-arrow-right"></i>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  productService: null,
  eventService: null,

  mounted() {},
  methods: {
    style() {
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let color = "#" + randomColor;
      return color;
    },
    click(item) {
      let href = "#";
      if (item.items != undefined && item.items != "undefined") {
        let child = item.items[0];

        if (child.items.length == 0) {
          href = item.to;
        } else {
          if (child.items != undefined && child.items != "undefined") {
            let subMenu = child.items[0];
            href = subMenu.to;
          }
        }
      }
      return href;
    },
  },
  computed: {
    ...mapGetters({ items: "gsdialogoauth/getMenu" }),
  },
};
</script>


