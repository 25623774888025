import etiquetaService from '@/service/GsDialogo/etiqueta'

export default {
    'ETIQUETA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            etiquetaService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'ETIQUETA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            etiquetaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'ETIQUETA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            etiquetaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'ETIQUETA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            etiquetaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
}