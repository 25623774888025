<template>
  <div class="layout-profile">
    <div>
      <img src="assets/layout/images/profile.png" alt="" />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ perfil.nombreCompleto }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="inicio">
            <i class="pi pi-fw pi-home"></i><span>Inicio</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i><span>Logout</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      expanded: true,
    };
  },
  methods: {
    logout() {
      this.$store.commit("gsdialogoauth/logout");
      this.$router.push({
        name: "login",
      });
    },
    inicio(){
       this.$router.push({
        name: "dashboard",
      });
    },
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    info() {
      this.$store
        .dispatch("gsdialogoauth/check")
        .then((res) => {
          console.info(res);
          this.fperfil();
          this.perfil.ejecutora = res.dataTramitador.oficina.ejecutora.codigo;
          
        })
        .catch((error) => {
          if (error.response.status == 401 || error.response.status == 500) {
            this.$store.commit("gsdialogoauth/logout");
            this.$router.push({
              name: "login",
            });
          }
        });
    },
    fperfil() {
      this.$store
        .dispatch("gsdialogoauth/info")
        .then((res) => {
          let mutacion = {
            menu: res,
          };
          this.$store.commit("gsdialogoauth/UMENU", mutacion);
        })
        .catch((error) => {
          if (error.response.status == 401 || error.response.status == 500) {
            this.$store.commit("gsdialogoauth/logout");
            this.$router.push({
              name: "login",
            });
          }
        });
    },
  },
  mounted() {
    this.info();
  },
  computed: {
    ...mapGetters({
      menu: "gsdialogoauth/getMenu",
      perfil: "gsdialogoauth/getUser",
    }),
  },
};
</script>

<style scoped>
</style>