export default {
    oficina: {
        "id": 0,
        "introduccion": "",
        "quienSoy": "",
        "telefono": "",
        "correo": "",
        "direccion": "",
        "jefatura": "",
        "estado": true
    }

}