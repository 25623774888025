<template>
	<div class="layout-footer">
		
		<img src="assets/layout/images/logo_bottom.png" alt="sigma" width="300"/>
		
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>