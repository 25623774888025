import documentoService from '@/service/GsDialogo/documento'

export default {
    'DOCUMENTO_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            documentoService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DOCUMENTO_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            documentoService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DOCUMENTO_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            documentoService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DOCUMENTO_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            documentoService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'FILE_UPLOAD': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            documentoService.upload(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}