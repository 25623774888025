import apiCall from '../../api';


const service = {
    findall(model) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/fianza/api/v1/carta/all?periodo='+model.periodo+'&ejecutora='+model.ejecutora+'&carpeta='+model.carpeta ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    findPeriodo() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/fianza/api/v1/carta/periodo').then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    mover(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/fianza/api/v1/carta/mover?idCarta='+model.id +'&idCarpeta='+model.carpeta.id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    save(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/fianza/api/v1/carta', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    delete(id) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.delete('/fianza/api/v1/carta?id='+id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    update(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/fianza/api/v1/carta', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    upload(file) {
        let formData = new FormData();
        formData.append("archivo", file.files[0]);
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.post('/fianza/api/v1/recursos/upload', formData ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    config(param) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/fianza/api/v1/recursos/config?id='+param ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
}
export default service;