export default {
    getModelEnviado: (state) => {
        return state.enviado
    },
    getEnviadoTpl: (state) => {
        if(state.operacion==="list"){
            return "EnviadoList"
        } else if(state.operacion==="new"){
            return "EnviadoNew"
        } else if(state.operacion==="edit"){
            return "EnviadoEdit"
        }
    }, 
}