export default {
    getModelUsuaDepe: (state) => {
        return state.usuaDepe
    },
    getUsuaDepeTpl: (state) => {
        if(state.operacion==="list"){
            return "UsuaDepeList"
        } else if(state.operacion==="new"){
            return "UsuaDepeNew"
        } else if(state.operacion==="edit"){
            return "UsuaDepeEdit"
        }
    }, 
}