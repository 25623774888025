import contratistaService from '@/service/GsFianza/contratista'

export default {
    'CONTRATISTA_LIST': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            contratistaService.findall(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CONTRATISTA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            contratistaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CONTRATISTA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            contratistaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CONTRATISTA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            contratistaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}