import tramiteService from '@/service/GsTupa/tramite'

export default {
    'TRAMITE_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_ENTRADA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.findallEntrada().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_ENVIADO_LIST': ({commit}) => {
        console.info("finalizado"+commit)
        return new Promise((resolve, reject) => {
            tramiteService.findAllEnviado().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_FINALIZADO_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.findAllFinalizado().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_RECEPCIONAR': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.recepcionar(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_FINALIZAR': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.finalizar(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_DERIVAR': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.derivar(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_REDERIVAR': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.rederivar(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TRAMITE_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tramiteService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
}