
import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
import GsFianza from './GsFianza'
import GsTupa from './GsTupa'
import GsSeguridad from './GsSeguridad'
import GsDialogo from './GsDialogo'

export const store = createStore({
  modules: {
    gsfianza: GsFianza,
    gsdialogoauth: GsSeguridad,
    gstupa :GsTupa,
    gsdialogo :GsDialogo
  },
  plugins: [createLogger(), createPersistedState()]
});