export default {
    etiqueta: {

        "id": 0,
        "nombre": "",
        "estado": true,
        "userRegistro": 0
    

    }
}