import authService from '@/service/GsSeguridad/auth'

export default {
    login({ commit }, user) {
        commit('auth_request')
        return new Promise((resolve, reject) => {
            authService.login(user)
                .then(resp => {
                    const token = resp.token
                    let dataUser = {
                        nombreCompleto: resp.user.nombreCompleto,
                        username: resp.user.username,
                        perfil: resp.user.authorities[0].authority,
                        usuario: resp.user.id,
                        ejecutora: ''
                    }
                    const user = dataUser
                    localStorage.setItem('token-type', resp.tokenType)
                    localStorage.setItem('user-token', token)
                    localStorage.setItem('user-data', JSON.stringify(user))
                    commit('auth_success', { token, user })
                    resolve();
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('user-data')
                    localStorage.removeItem('token-type')
                    let objErr = {
                        title: 'Error al ingresar a tu sesión',
                        message: 'Revisa que los datos que ingresaste sean los correctos'
                    }
                    console.info(err)
                    reject(objErr)
                })
        })
    },
    info() {
        return new Promise((resolve, reject) => {
            authService.info().then((res) => {
                let menu = [];
                
                (res.accesoMenus).forEach(element => {
                    // cabeceras tipo title
                    //console.log(element.menus);
                    let child_0_1= [];
                    if (element.menus != undefined && element.menus != "undefined") {
                        
                        for (let i = 0; i < element.menus.length; i++) {
                            let men = element.menus[i];
                           
                            if (men.subMenus.length == 0) {
                                child_0_1.push({
                                    label: men.descripcion,
                                    icon: men.icon,
                                    to: men.url
                                })
                               
                            } else {
                                let child_0 = [];
                                if (men.subMenus != undefined && men.subMenus != "undefined") {
                                    for (let j = 0; j < men.subMenus.length; j++) {
                                        let subMenu = men.subMenus[j];
                                        child_0.push({
                                            label: subMenu.descripcion,
                                            to: subMenu.url,
                                            icon: subMenu.icon
                                        })
                                    }
                                }
                                child_0_1.push({
                                    label: men.descripcion,
                                    icon: men.icon,
                                    items: child_0
                                })
                            }
                        }
                    }
                    menu.push({
                        label: element.descripcion,
                        header: true,
                        icon: element.icono,
                        items:child_0_1
                    })

                });
                resolve(menu)
            }).catch(err => {
                reject(err)
            })
        })

    },
    infologin({ commit }, params) {
        console.info(commit)
        return new Promise((resolve, reject) => {
            authService.infologin(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    saveperfil({ commit }, params) {
        console.info(commit)
        return new Promise((resolve, reject) => {
            authService.saveperfil(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    check({ commit }) {
        console.info(commit)
        return new Promise((resolve, reject) => {
            authService.check().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            commit('logout')
            authService.logout();
            resolve();
            console.info(reject)
        })
    }
}