export default {
    getModelTupa: (state) => {
        return state.tupa
    },
    getTupaTpl: (state) => {
        if(state.operacion==="list"){
            return "TupaList"
        } else if(state.operacion==="new"){
            return "TupaNew"
        } else if(state.operacion==="edit"){
            return "TupaEdit"
        }
    }, 
    getUrl: (state) => {
        return state.url
    }
}