import dependenciaService from '@/service/GsTupa/dependencia'

export default {
    'DEPENDENCIA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },

    'DEPENDENCIA_USUA_LIST': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.findalldepe(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DEPENDENCIA_USUA_DEPE_LIST': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.findallusuadepe(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DEPENDENCIA_USUA_DEPE_SAVE': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.saveusuadepe(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    

    'DEPENDENCIA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DEPENDENCIA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'DEPENDENCIA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            dependenciaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
}