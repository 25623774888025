import apiCall from '../../api';


const service = {
    findall() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
                apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/pago/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    
    findone(model) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
                apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/solicitud/one?id='+model ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },

    save(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/pago', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    delete(id) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.delete('/tupa/api/v1/pago?id='+id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    update(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/tupa/api/v1/pago', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    }
}
export default service;