export default {
    getModelMult: (state) => {
        return state.multimedia
    },
    getMultTpl: (state) => {
        
        if(state.operacion==="list"){
            return "MultimediaList"
        } else if(state.operacion==="new"){
            return "MultimediaNew"
        } else if(state.operacion==="edit"){
            return "MultimediaEdit"
        }

    }, 
    getUrl: (state) => {
        return state.url
    }
}