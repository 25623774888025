export default {
    getModelEntrada: (state) => {
        return state.entrada
    },
    getEntradaTpl: (state) => {
        if(state.operacion==="list"){
            return "EntradaList"
        } else if(state.operacion==="new"){
            return "EntradaNew"
        } else if(state.operacion==="edit"){
            return "EntradaEdit"
        }
    }, 
}