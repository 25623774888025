export default {
    operacion: "list",
    carta: {
        "ejecutora" :'',
        "numero" : '',
        "obra" : {
            "nombre": ''
        },
        "estado": false
    }

}