export default {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, { token, user }) {
        state.status = 'success'
        state.token = token
        state.user = user
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
        state.user = {}
    },
    acceso_menus(state, accesoMenus){        
        state.accesoMenus = accesoMenus
    },
    'UMENU': (state, mutation) => {
        state.menu = mutation.menu
    },
    'UCOLOR': (state, mutation) => {
        state.d_layoutColorMode = mutation.color
    }
}