export default {
    documento: {
        "id": 0,
        "fechaDocumento": new Date(),

        "resumen": "",
        "titulo": "",
        "ruta": "",
        "estado": true,
        "anho": 0,
        "extension": "",
        "tipoArchivo": "",
        "userRegistro": 0,
        "rating": 0,
        "detalle": [{
            "id": 0,
            "etiqueta": {
                
            },
            "documento": {
                "id" : 0
            },
            "estado": true
        }]

    }

}