import tupaAction from './actions/tupa'
import tupaGetter from './getters/tupa'
import tupaMutation from './mutations/tupa'
import tupaState from './states/tupa'

import dependenciaAction from './actions/dependencia'
import dependenciaGetter from './getters/dependencia'
import dependenciaMutation from './mutations/dependencia'
import dependenciaState from './states/dependencia'


import pagoAction from './actions/pago'
import pagoGetter from './getters/pago'
import pagoMutation from './mutations/pago'
import pagoState from './states/pago'


import tramiteAction from './actions/tramite'
import entradaGetter from './getters/entrada'
import entradaMutation from './mutations/entrada'
import entradaState from './states/entrada'

import enviadoGetter from './getters/enviado'
import enviadoMutation from './mutations/enviado'
import enviadoState from './states/enviado'

import finalizadoGetter from './getters/finalizado'
import finalizadoMutation from './mutations/finalizado'
import finalizadoState from './states/finalizado'


import usuaDepeGetter from './getters/usuaDepe'
import usuaDepeMutation from './mutations/usuaDepe'
import usuaDepeState from './states/usuaDepe'

const actions = Object.assign({}, tupaAction, dependenciaAction,pagoAction,  tramiteAction)
const getters = Object.assign({}, tupaGetter, dependenciaGetter,pagoGetter, entradaGetter, enviadoGetter,finalizadoGetter , usuaDepeGetter)
const mutations = Object.assign({}, tupaMutation, dependenciaMutation,pagoMutation, entradaMutation, enviadoMutation, finalizadoMutation, usuaDepeMutation)
const state = Object.assign({}, tupaState, dependenciaState,pagoState, entradaState, enviadoState, finalizadoState, usuaDepeState)

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
}