import pagoService from '@/service/GsTupa/pago'

export default {
    'PAGO_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            pagoService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'SOLICITUD_ONE': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            pagoService.findone(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'PAGO_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            pagoService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'PAGO_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            pagoService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'PAGO_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            pagoService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
}