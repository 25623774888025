import obraService from '@/service/GsFianza/obra'

export default {
    'OBRA_LIST': ({commit}, params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            obraService.findall(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'OBRA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            obraService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'OBRA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            obraService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'OBRA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            obraService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}