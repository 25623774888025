// import actions
import authAction from './actions/auth'


// import getters
import authGetter from './getters/auth'


// import mutations
import authMutation from './mutations/auth'

// import states
import authState from './states/auth'


const actions = Object.assign({}, authAction )

const state = Object.assign({}, authState)

const getters = Object.assign({}, authGetter)

const mutations = Object.assign({}, authMutation)

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
}