import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        meta: { requiresAuth: true },
        component: Dashboard,
    },
    {
        path: '/fianza/obras',
        name: 'fianza/obras',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/obra/obra.vue'),
    },
    {
        path: '/fianza/tipo_fianzas',
        name: 'fianza/tipo_fianzas',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/tipo_fianza/tipo_fianza.vue'),
    },
    {
        path: '/fianza/bancos',
        name: 'fianza/bancos',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/banco/banco.vue'),
    },
    {
        path: '/fianza/contratistas',
        name: 'fianza/contratistas',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/contratista/contratista.vue'),
    },
    {
        path: '/tupa/dependencia',
        name: 'tupa/dependencia',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/dependencia/dependencia.vue'),
    },
    {
        path: '/tupa/entrada',
        name: 'tupa/entrada',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/entrada/entrada.vue'),
    },
    {
        path: '/tupa/reporrtes',
        name: 'tupa/reporrtes',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/reporte/reporte.vue'),
    },
    {
        path: '/tupa/usuadepe',
        name: 'tupa/usuadepe',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/usuario/usuaDepe.vue'),
    },
    {
        path: '/tupa/archivados',
        name: 'tupa/archivados',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/finalizado/finalizado.vue'),
    },
    {
        path: '/tupa/enviados',
        name: 'tupa/enviados',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/enviado/enviado.vue'),
    },
    {
        path : '/tupa/dependencia/list',
        name : 'tupa/dependencia/list',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/dependencia/dependenciaList.vue'),
    },
    {
        path: '/tupa/pago',
        name: 'tupa/pago',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/pago/pago.vue'),
    },
    {
        path: '/tupa/pago/new',
        name: 'tupa/pago/new',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/pago/pagoNew.vue'),
    },
    {
        path: '/tupa/amigable',
        name: 'tupa/amigable',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/tupa/tupa.vue'),
    },
    {
        path : '/tupa/amigable/list',
        name : 'tupa/amigable/list',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/tupa/tupaList.vue'),
    },
    {
        path : '/tupa/amigable/new',
        name : 'tupa/amigable/new',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/tupa/tupaNew.vue'),
    },
    {
        path : '/tupa/amigable/edit',
        name : 'tupa/amigable/edit',
        meta: { requiresAuth: true },
        component: () => import('./applications/gstupa/tupa/tupaEdit.vue'),
    },
    {
        path: '/fianza/cartas',
        name: 'fianza/cartas',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/carta/carta.vue'),
        
    },
    {
        path : '/fianza/cartas/new',
        name : 'fianza/cartas/new',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/carta/cartaNew.vue'),
    },
    {
        path : '/fianza/cartas/list',
        name : 'fianza/cartas/list',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/carta/cartaList.vue'),
    },
    
    {
        path : '/fianza/cartas/edit',
        name : 'fianza/cartas/edit',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsfianza/carta/cartaEdit.vue'),
    },
    
    {
        path: '/login',
        name: 'login',
        meta: { requiresAuth: false },
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/dialogo/documento',
        name: 'dialogo/documento',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsdialogo/documento/documento.vue'),
    },
    {
        path: '/dialogo/etiqueta',
        name: 'dialogo/etiqueta',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsdialogo/etiqueta/etiqueta.vue'),
    },

    {
        path: '/dialogo/oficina',
        name: 'dialogo/oficina',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsdialogo/oficina/oficina.vue'),
    },
    {
        path: '/dialogo/publicacion',
        name: 'dialogo/publicacion',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsdialogo/publicacion/publicacion.vue'),
    },
    {
        path: '/dialogo/multimedia',
        name: 'dialogo/multimedia',
        meta: { requiresAuth: true },
        component: () => import('./applications/gsdialogo/multimedia/multimedia.vue'),
    }
   
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
