import cartaService from '@/service/GsFianza/carta'

export default {
    'CARTA_LIST': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.findall(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CARTA_PERIODO': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.findPeriodo().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CONFIG': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.config(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CARTA_MOVER': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.mover(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CARTA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CARTA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'CARTA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'FILE_UPLOAD': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            cartaService.upload(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}