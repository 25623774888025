export default {
    /* AUTH */
    token: localStorage.getItem('user-token') || '',
    status: '',
    accesoMenus:[],
    /* USER */
    user: JSON.parse(localStorage.getItem('user-data')) || {},
    layoutMode: {
      type: String,
      default: null
    },
    d_layoutColorMode: "",
    menu: [
        {
          label: "Dialogo Sotenible",
          icon: "pi pi-fw pi-sitemap",
          visible: false,
          items: [
            {
              label: "Gestor Web",
              icon: "pi pi-fw pi-globe",
              visible: false,
              items: [
                {
                  label: "Documentos",
                  icon: "pi pi-fw pi-file",
                  to: "/dialogo/documento",
                  visible: false
                },
                {
                  label: "Publicaciones",
                  icon: "pi pi-fw pi-file",
                  to: "/dialogo/publicacion",
                  visible: false
                },
                {
                  label: "Multimedia",
                  icon: "pi pi-fw pi-image",
                  to: "/dialogo/multimedia",
                  visible: false
                },
              ],
            },
            {
              label: "Mantenimiento",
              icon: "pi pi-fw pi-cog",
              visible: false,
              items: [
                {
                  label: "Etiquetas",
                  icon: "pi pi-fw pi-cog",
                  to: "/dialogo/etiqueta",
                  visible: false
                },
                {
                  label: "Oficina",
                  icon: "pi pi-fw pi-home",
                  to: "/dialogo/oficina",
                  visible: false
                },
              ],
            },
          ],
        },
      ],
}