import oficinaService from '@/service/GsDialogo/oficina'

export default {
    'OFICINA_GET': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            oficinaService.find().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    
    'OFICINA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            oficinaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
   
}