import tipoFianzaService from '@/service/GsFianza/tipo_fianza'

export default {
    'TIPO_FIANZA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tipoFianzaService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TIPO_FIANZA_SAVE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tipoFianzaService.save(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TIPO_FIANZA_UPDATE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tipoFianzaService.update(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
    'TIPO_FIANZA_DELETE': ({commit},params) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            tipoFianzaService.delete(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    },
}