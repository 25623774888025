import documentoAction from './actions/documento'
import documentoGetter from './getters/documento'
import documentoMutation from './mutations/documento'
import documentoState from './states/documento'

import etiquetaAction from './actions/etiqueta'
import etiquetaGetter from './getters/etiqueta'
import etiquetaMutation from './mutations/etiqueta'
import etiquetaState from './states/etiqueta'


import oficinaAction from './actions/oficina'
import oficinaGetter from './getters/oficina'
import oficinaMutation from './mutations/oficina'
import oficinaState from './states/oficina'

import publicacionAction from './actions/publicacion'
import publicacionGetter from './getters/publicacion'
import publicacionMutation from './mutations/publicacion'
import publicacionState from './states/publicacion'

import multimediaAction from './actions/multimedia'
import multimediaGetter from './getters/multimedia'
import multimediaMutation from './mutations/multimedia'
import multimediaState from './states/multimedia'


const actions = Object.assign({}, documentoAction, etiquetaAction, oficinaAction, publicacionAction, multimediaAction)
const getters = Object.assign({}, documentoGetter, etiquetaGetter, oficinaGetter, publicacionGetter, multimediaGetter)
const mutations = Object.assign({}, documentoMutation, etiquetaMutation, oficinaMutation, publicacionMutation, multimediaMutation)
const state = Object.assign({}, documentoState, etiquetaState, oficinaState,publicacionState, multimediaState)

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
}