

export default {
  operacion: "list",
  multimedia: {
    "id": 0,
    
    "fechaPublicacion": new Date(),
    
    "titulo": "",
    "descripcion": "",
    "anulado": true,
    "userRegistro": 0,
    "detalle": []
  },
 

}