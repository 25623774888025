export default {
    publicacion: {
        id: 0,
        fechaInicio: new Date(),
        titulo: "",
        resumen: "",
        enlace: "",
        ruta: "",
        anulado: false,
        tipoArchivo:"",
        extension:"",
        tipo: {}
    }
}