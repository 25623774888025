import axios from 'axios';

//if (process.env.NODE_ENV !== 'production') baseURL = "http://localhost:8082"
let baseURL = "https://dev2.regionsanmartin.gob.pe"
if (process.env.NODE_ENV !== 'production') baseURL = "http://localhost:8083"
const axiosConfig = {
    baseURL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    }
};

const apiCall = axios.create(axiosConfig);
export default apiCall;