import apiCall from '../../api';

const service = {
    login(user) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = "";
            apiCall.post('/securytramite/api/auth', user).then(res => resolve(res.data)).catch(err => reject(err));
        })
    },
    saveperfil(model) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = "";
            apiCall.post('/securytramite/api/putrol?usuario='+model+'&perfil=7').then(res => resolve(res.data)).catch(err => reject(err));
        })
    },
    info() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
            apiCall.get('/securytramite/api/info').then(res => resolve(res.data)).catch(err => reject(err));
        })
    },
    infologin(model) {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
            apiCall.get('/securytramite/api/infologin?login='+model).then(res => resolve(res.data)).catch(err => reject(err));
        })
    },
    check() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
            apiCall.get('/securytramite/api/check').then(res => resolve(res.data)).catch(err => reject(err));
        })
    },
    logout() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production') apiCall.defaults.baseURL = "https://dev2.regionsanmartin.gob.pe"
            apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
            apiCall.delete("/securytramite/api/logout").then(() => {
                localStorage.removeItem('user-token')
                localStorage.removeItem('token-type')
                localStorage.removeItem('user-data')
                
                delete apiCall.defaults.headers.common['Authorization']

            }).catch(err => {
                reject(err);
            });
        })
    },
}

export default service