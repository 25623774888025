export default {
    getModelPago: (state) => {
        return state.pago
    },
    getPagoTpl: (state) => {
        if(state.operacion==="list"){
            return "PagoList"
        } else if(state.operacion==="new"){
            return "PagoNew"
        } else if(state.operacion==="edit"){
            return "PagoEdit"
        }
    }, 
}