import apiCall from '../../api';

const service = {
    findall() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8081"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/gsdialogo/api/v1/documento/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    save(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8081"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/gsdialogo/api/v1/documento', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    delete(id) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8081"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.delete('/gsdialogo/api/v1/documento?id='+id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    update(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8081"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/gsdialogo/api/v1/documento', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    upload(file) {
        let formData = new FormData();
        formData.append("archivo", file.files[0]);
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8081"
                apiCall.post('/gsdialogo/api/v1/recursos/upload', formData ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    }
}
export default service;