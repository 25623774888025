import carpetaService from '@/service/GsFianza/carpeta'

export default {
    'CARPETA_LIST': ({commit}) => {
        console.info(commit)
        return new Promise((resolve, reject) => {
            carpetaService.findall().then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
}