export default {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getAccesoMenus:state=>state.getAccesoMenus,
    getMenu: (state) => {
        return state.menu
    },
    getLayoutColorMode: (state) =>  state.d_layoutColorMode
    
}