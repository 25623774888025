import apiCall from '../../api';


const service = {
    findall() {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
                apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/fianza/api/v1/tipo_fianza/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    save(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/fianza/api/v1/tipo_fianza', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    delete(id) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.delete('/fianza/api/v1/tipo_fianza?id='+id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    update(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8083"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/fianza/api/v1/tipo_fianza', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    }
}
export default service;