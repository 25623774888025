export default {
    getModelDependencia: (state) => {
        return state.dependencia
    },
    getDependenciaTpl: (state) => {
        if(state.operacion==="list"){
            return "DependenciaList"
        } else if(state.operacion==="new"){
            return "DependenciaNew"
        } else if(state.operacion==="edit"){
            return "DependenciaEdit"
        }
    }, 
}