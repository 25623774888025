import apiCall from '../../api';


const service = {
    findall(model) {
        console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/tramite/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    findallEntrada(model) {
        console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/tramite/entrada/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    findAllEnviado(model) {
        console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/tramite/enviado/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    findAllFinalizado(model) {
        console.info("Finalizado:"+model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.get('/tupa/api/v1/tramite/finalizado/all' ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    recepcionar(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/tramite/recepcionar?id='+model ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },

    finalizar(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/tramite/finalizar?id='+model.id+"&text="+model.text ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    derivar(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/tramite/derivar?usua='+model.usua+'&dede='+model.dede+'&id='+model.id+"&text="+model.text ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    rederivar(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/tramite/rederivar?usua='+model.usua+'&dede='+model.dede+'&id='+model.id+"&text="+model.text ).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    mover(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/tupa/api/v1/tramite/mover?idtupa='+model.id +'&idCarpeta='+model.carpeta.id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    save(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.post('/tupa/api/v1/tramite', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    delete(id) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.delete('/tupa/api/v1/tramite?id='+id).then(res => resolve(res.data)).catch(err => reject(err));
        });
    },
    update(model) {
        //console.info(model)
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV !== 'production')
            apiCall.defaults.baseURL = "http://localhost:8085"
                apiCall.defaults.headers.common['Authorization'] = localStorage.getItem("token-type") + " " + localStorage.getItem("user-token");
                apiCall.put('/tupa/api/v1/tramite', model).then(res => resolve(res.data)).catch(err => reject(err));
        });
    }
}
export default service;